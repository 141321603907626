html {
  height: 100vh
}

body {
  height: 100vh;
  overflow: hidden;
  margin: 0;
  display: flex;
  box-sizing: border-box
}

#root {
  display: flex;  
  width: 100vw;
  height: 100vh;
}

#map {
  display: flex;
  z-index: 1;
}

#map2 {
  display: flex;
  z-index: 1;
}

#map {
  flex: 1;
  z-index: 1;
}

#map2 {
  flex: 1;
  z-index: 1;
}

.overlay-button {
  position: fixed;
  top :20px;
  right: 20px;
  z-index: 9999;
}